import 'bootstrap/dist/css/bootstrap.min.css';
 import './App.css';
import Navbar from './components/Navbar'

function Navvy() {
  return (
    <Navbar/>  
  );
}

export default Navvy;
